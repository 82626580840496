
import { Component, Vue } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast } from "vant";
import globalConfig from "src/config/index";

@Component
export default class Login extends Vue {
  // 企业列表
  private employerList: Array<any> = [];

  /**
   * 获取routeName
   * @private
   * @returns void
   */
  private get routeName(): any {
    return this.$route.query.routeName || "";
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    this.changeToken();
    this.getOrgList();
  }

  /**
   * 获取企业列表
   * @private
   * @returns void
   */
  private async getOrgList(): Promise<void> {
    try {
      let { content: res } = await UserService.instance.getOrgList();
      // if(res.data && res.data.length == 1)
      // {
      //     this.changeToken(res.data[0])
      // }
      // else
      // {
      this.employerList = res.data || [];
      // }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取企业列表
   * @private
   * @returns void
   */
  private async changeToken(item?: any): Promise<void> {
    try {
      let { content: result } = await UserService.instance.changeToken((item && item.id) || null);
      if (result.data) {
        this.$store.dispatch("setOrgInfo", item);
        this.$store.dispatch("setUserInfo", result.data);
        if (item) {
          this.$nextTick(() => {
            if (this.routeName == "report") {
              this.$router.push({ name: "reportAppointmentList" });
            } else {
              this.$router.replace({ name: "home" });
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
      Toast(err);
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }
}
